import { Col, Input, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import { storeTemplateApi } from 'feature/store-template/api'
import StoreTemplateTable from 'feature/store-template/componont/StoreTemplateTable'

import { StoreTemplate } from 'type/client/storeTemplate'
import { emptyPage, Page } from 'type/client/type'

const StoreTemplatePage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [storeTemplates, setStoreTemplates] =
    useState<Page<StoreTemplate>>(emptyPage)

  useEffect(() => {
    loadFirstPage()
  }, [])

  const loadStoreTemplates = async (page: number) => {
    setIsLoading(true)
    try {
      const response = await storeTemplateApi.getAll(page, query)
      setStoreTemplates(response)
    } catch (error) {
      message.error('가게 템플릿을 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  const handleSearch = () => loadFirstPage()

  const loadFirstPage = () => loadStoreTemplates(0)

  return (
    <>
      <Row>
        <div className={'text-2xl font-bold mb-3'}>가게 검색 템플릿 관리</div>
      </Row>
      <Row className={'mb-6'}>
        <Col span={8}>
          <Input.Search
            onSearch={handleSearch}
            onChange={handleSearchChange}
            placeholder={'가게명을 검색하세요'}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StoreTemplateTable
            isLoading={isLoading}
            storeTemplates={storeTemplates}
            loadStoreTemplates={loadStoreTemplates}
          />
        </Col>
      </Row>
    </>
  )
}

export default StoreTemplatePage

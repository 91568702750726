import axios from 'axios'

import { AthensInstance } from 'type/lib/axios'

let baseUrl = ''
if (process.env.REACT_APP_HOST !== 'local') {
  baseUrl = `http://${process.env.REACT_APP_HOST}`
}

export const adminAxios: AthensInstance = axios.create({
  baseURL: `${baseUrl}/api`,
  timeout: 30000,
})

adminAxios.interceptors.request.use(
  async config => config,
  async error => Promise.reject(error)
)

adminAxios.interceptors.response.use(
  async response => response.data.result,
  async error => Promise.reject(error)
)

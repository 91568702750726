import { RoutePath } from 'enum/RoutePath'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import MainLayout from 'feature/ui/components/layout/MainLayout'

import HomePage from 'page/home'
import LoungePage from 'page/lounge'
import NoticePage from 'page/notice'
import StickerPage from 'page/sticker'
import StorePage from 'page/store'
import StoreRegistrationPage from 'page/storeRegistration'
import StoreTemplatePage from 'page/storeTemplate'
import TermPage from 'page/terms'

const routes = [
  {
    path: '/login',
    element: <div>Login</div>,
  },
  {
    path: RoutePath.Home,
    element: <HomePage />,
  },
  {
    path: RoutePath.Notice,
    element: <NoticePage />,
  },
  {
    path: RoutePath.Terms,
    element: <TermPage />,
  },
  {
    path: RoutePath.Store,
    element: <StorePage />,
  },
  {
    path: RoutePath.StoreRegistration,
    element: <StoreRegistrationPage />,
  },
  {
    path: RoutePath.StoreTemplate,
    element: <StoreTemplatePage />,
  },
  {
    path: RoutePath.Lounge,
    element: <LoungePage />,
  },
  {
    path: RoutePath.Sticker,
    element: <StickerPage />,
  },
]

const MainRoutes = () => (
  <Suspense fallback={''}>
    <Routes>
      <Route element={<MainLayout />}>
        {routes.map(route => (
          <Route
            key={route.path}
            {...route}
          />
        ))}
      </Route>
    </Routes>
  </Suspense>
)

export default MainRoutes

import { RcFile } from 'antd/lib/upload'

import { adminAxios } from 'config/adminClientConfig'

import {
  DeleteStoreTemplatePayload,
  StoreTemplate,
} from 'type/client/storeTemplate'
import { Page } from 'type/client/type'

const getAll = async (page: number, keyword: string) => {
  return adminAxios.get<Page<StoreTemplate>>(
    `/store-templates?page=${page}&keyword=${keyword}`
  )
}

const uploadCsv = async (csvFile: RcFile) => {
  const formData = new FormData()
  formData.append('file', csvFile)

  return adminAxios.post<void>('/store-templates/upload', formData)
}

const deleteAll = async (params: DeleteStoreTemplatePayload) => {
  return adminAxios.delete<void>(`/store-templates`, {
    data: params,
  })
}

export const storeTemplateApi = {
  getAll,
  uploadCsv,
  deleteAll,
}

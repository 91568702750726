import { Alert, Button, message, Table, TableProps, Upload } from 'antd'
import React from 'react'

import { ColumnsType } from 'antd/es/table'
import {
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/es/table/interface'
import { RcFile } from 'antd/es/upload/interface'

import { addressToString } from 'feature/core/util/address'
import { storeTemplateApi } from 'feature/store-template/api'

import { StoreTemplate } from 'type/client/storeTemplate'
import { Page } from 'type/client/type'

const StoreTemplateTable = ({
  isLoading,
  storeTemplates,
  loadStoreTemplates,
}: {
  isLoading: boolean
  storeTemplates: Page<StoreTemplate>
  loadStoreTemplates: (page: number) => void
}) => {
  const [selectedRows, setSelectedRows] = React.useState<StoreTemplate[]>([])
  const [uploading, setUploading] = React.useState(false)

  const handleRejectATagClick = (storeTemplate: StoreTemplate) => {
    return async () => {
      await storeTemplateApi.deleteAll({ ids: [storeTemplate.id] })
      reloadCurrentPage()
    }
  }

  const handleTableChange: TableProps['onChange'] = (
    paginationConfig,
    _filters,
    _sorter
  ) => {
    const page = (paginationConfig.current || 1) - 1
    setSelectedRows([])
    loadStoreTemplates(page)
  }

  const uploadCustomRequest = async (file: RcFile) => {
    try {
      setUploading(true)
      await storeTemplateApi.uploadCsv(file)
      message.success('가게 템플릿 업로드에 성공했습니다.')
      reloadCurrentPage()
    } catch (e) {
      message.error('가게 템플릿 업로드에 실패했습니다.')
    } finally {
      setUploading(false)
    }
  }

  const handleDeleteAllBtnClick = async () => {
    const ids = selectedRows.map(value => value.id)
    await storeTemplateApi.deleteAll({ ids })
    reloadCurrentPage()
  }

  const tableRowSelection: TableRowSelection<StoreTemplate> = {
    selectedRowKeys: selectedRows.map(value => value.id),
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRows([...selectedRows, record])
      } else {
        setSelectedRows(prevState =>
          prevState.filter(selectedRow => selectedRow.id !== record.id)
        )
      }
    },
    onChange: (_selectedRowKeys, selectedRows, _info) => {
      setSelectedRows(selectedRows)
    },
  }

  const tablePagination: TablePaginationConfig = {
    current: storeTemplates.number + 1,
    pageSize: storeTemplates.size,
    total: storeTemplates.totalElements,
  }

  const reloadCurrentPage = () => {
    loadStoreTemplates(storeTemplates.number)
  }

  const columns: ColumnsType<StoreTemplate> = [
    {
      title: '가게명',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => addressToString(record.address),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '',
      width: 300,
      key: 'action',
      render: (_, record) => (
        <div className={'flex gap-3'}>
          <a onClick={handleRejectATagClick(record)}>삭제</a>
        </div>
      ),
    },
  ]

  return (
    <div className={'flex flex-col gap-3'}>
      <Alert
        message={
          <div>
            csv 파일을 업로드하여 가게 템플릿을 추가할 수 있습니다.
            <br />
            <div className={'font-bold'}>
              csv 파일은 가게명, 도로명 주소, 지번 주소, 도로명 우편번호,
              위도(y), 경도(x) 순서로 구성되어야 합니다.
            </div>
          </div>
        }
        type="info"
        showIcon
      />
      <div className={'flex gap-3'}>
        <Upload
          showUploadList={false}
          customRequest={({ file }) => uploadCustomRequest(file as RcFile)}
        >
          <Button
            type={'primary'}
            loading={uploading}
          >
            가게 템플릿 업로드
          </Button>
        </Upload>
        <Button onClick={handleDeleteAllBtnClick}>선택 가게 템플릿 삭제</Button>
      </div>
      <Table
        rowSelection={tableRowSelection}
        onChange={handleTableChange}
        loading={isLoading}
        rowKey={'id'}
        dataSource={storeTemplates.content}
        columns={columns}
        bordered={true}
        scroll={{ y: 580 }}
        pagination={tablePagination}
      />
    </div>
  )
}

export default StoreTemplateTable
